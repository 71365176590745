//促销活动相关接口

import myaxios from "../MyAxios";
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const URL = URL_ENV.BASEURL

export default {

    /** 获取促销活动列表
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "pageSize": 20,
     *     "pageIndex": 1,
     *     "totalNum": 0,
     *     "totalPage": 0,
     *     "result": [],
     *     "extra": {}
     *   }
     * }
     * */
    queryActivityList() {
        return myaxios.get(URL + 'promotion/list', {'pageSize': 100});
    },
    /** 获取促销活动详情
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "id": 0,
     *     "title": null,
     *     "title_CN": null,
     *     "title_SP": null,
     *     "isIndex": 0,
     *     "photo": null,
     *     "photo_CN": null,
     *     "photo_SP": null,
     *     "detail": null,
     *     "detail_CN": null,
     *     "detail_SP": null,
     *     "createTime": null,
     *     "sort": 0
     *   }
     * }
     * */
    queryActivityDetail(id) {
        return myaxios.get(URL + 'promotion/' + id, {});
    }
}