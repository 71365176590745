// 在该文件中, 应导出所有Api接口模块对象

import orderNowApi from "./apis/orderNow"
import carDetailApi from "./apis/carDetail"
import carApi from "./apis/car"
import activityApi from "./apis/activity"
import commonApi from "./apis/common"
import distributorApi from "./apis/distributor"
import homeApi from "./apis/home"
import joinAfterSaleApi from "./apis/joinAfterSale"
import otherGoodsApi from "./apis/otherGoods"

const httpApi = {
    orderNowApi,
    carDetailApi,
    carApi,
    activityApi,
    commonApi,
    distributorApi,
    homeApi,
    joinAfterSaleApi,
    otherGoodsApi

}

export default httpApi
