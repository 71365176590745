// 存放所有立即订购相关接口

import myaxios from "../MyAxios"
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const BYDURL = URL_ENV.DEV.BMDURL // 使用普通的(还有一个上传文件的)

export default {
    /**
     * 通过model_id查询车详情
     * @param {object} params
     * 参数列表{model_id:1}
     */
    queryCarDetailByModelId(params) {
        return myaxios.get(BYDURL + "/v1/vehicle/vehicle_merchandise_id", params)
    },
}
