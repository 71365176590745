export default {
    "index": {
        "drive_test": "Test Drive Reservation",
        "know_him": "Detailed Specifications",
        "modelos": "Model",
        "view_more": "Learn More",
        "why_ff": "Reasons to Choose FF",
        "other_goods": "Merchandising",
        "subscribe": "Subscribe to Us"
    },
    "header": {
        "about_ff": "About Us",
        "service_workshops": "After-Sales Service",
        "find_distributor": "Franchise Opportunities",
        "join_us": "Join Us",
        "distributor": "Dealers",
        "other_goods": "Merchandising",
        "area_lang": "Region/Language"
    },
    "footer": {
        "quick_link": "Quick Links",
        "activity_list": "Promotions",
        "other_goods": "Surrounding Products",
        "drive_test": "Test Drive Reservation",
        "question_list": "Frequently Asked Questions",
        "private_policy": "Privacy Policy",
        "contact": "Contact Us",
        "terms_use": "Terms of Use",
        "support": "Technical Support",
        "networks": "Network",
        "copyright": "Copyright@foalcar",
        "copyright_number": "Guangdong ICP No. 10216027",
        "accept_cookies": "We use our own and third-party cookies to ensure the correct operation of the website and its plugins. If you continue to visit, please accept the above context. For more information, please refer to our privacy policy.",
        "accept": "Accept",
        "refuse": "Decline"
    },
    "activity": {
        "activity_center": "Promotions"
    },
    "car": {
        "guiding_price": "Guidance Price",
        "view_360": "360° Car View",
        "view_drag_mouse": "Drag the mouse to explore",
        "interior": "Interior",
        "exterior": "Exterior",
        "view_tip": "This section is for content display，actual vehicle configurations are subject to the delivered car.",
        "order_now": "Customize Now"
    },
    "distributor": {
        "distributor_list": "FOAL Dealer List"
    },
    "join_us": {
        "joining_conditions": "Franchise Conditions",
        "joining_process": "Franchise Process",
        "contact_us": "Contact Us",
        "contact_tel": "Hotline",
        "contact_email": "Contact Email",
        "address_detail": "Detailed Address",
        "tip_address": "Please enter the detailed address",
        "company_name": "Company",
        "tip_companyName": "Please enter the company name",
        "company_industry": "Industry",
        "tip_companyIndustry": "Please enter the industry",
        "join_money": "Franchise Amount",
        "select_money": "Please select currency",
        "tip_money": "Please enter franchise amount",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "loading": "Submitting...",
        "join_us": "Apply for Franchise",
        "submit_success": "Submission successful, we will contact you as soon as possible."
    },
    "order": {
        "confirm_information": "Confirm Personal Information",
        "name": "Last Name",
        "phone": "Phone Number",
        "email": "Email",
        "selected_customization": "Selected Customization",
        "tip": "The images used for the vehicle configuration above are for reference only; specific product details are subject to the final delivered car.",
        "selected_area": "Province/City/Dealer",
        "area": "Region",
        "distributor": "Dealer",
        "please_select": "Please select",
        "car_information": "Product Information",
        "car_selected_price": "Preselected Product Amount",
        "agree": "I agree and check",
        "agree_message": "Order Notice",
        "change_info": "Modify Configuration",
        "submit_info": "Submit Configuration",
        "tip_name": "Please enter name",
        "tip_phone": "Please enter phone number",
        "tip_number": "Please enter a number",
        "tip_email": "Please enter email"
    },
    "other_goods": {
        "country": "Country",
        "select_country": "Please select country",
        "mx": "Mexico",
        "zh": "China",
        "am": "United States",
        "name": "Last Name",
        "phone": "Phone Number",
        "email": "Email",
        "click_now": "Click to learn more",
        "detail": "Details",
        "submit_success": "Congratulations! Submission successful, a staff member will contact you shortly!"
    },
    "service_center": {
        "list": "FOAL After-Sales Service List"
    },
    "subscribe": {
        "title": "Hemos determinado tu ubicacion actual",
        "remark": "Haciendo clic en \"Continuar hacia myAudi\"aceptas expresamente el redireccionamiento automatico a la pagina web del pais seleccionado.Ademas,aceptas de ahora en adelante el redireccionamiento automatico a la pagina web del pais seleccionado.En esta pagina podras seleccionar otro pais otra region en cualquier momento.",
        "confirm_subscribe": "Submit Subscription",
        "submit_success": "Subscription successful!"
    },
    "drive_test": {
        "drive_test": "Test Drive Reservation",
        "tip": "Welcome to book a test drive online. Please fill in the following information accurately, and we will provide you with a better driving experience.",
        "select_info": "Select the information you want to test drive:",
        "type": "Model:",
        "select_date": "Experience Time:",
        "select_date_tip": "Please select date:",
        "buyer_info": "Please fill in the contact information:",
        "agree": "I have read and agree to the privacy policy",
        "confirm_drive": "Apply for Test Drive",
        "submit_success": "Test drive information added successfully, looking forward to your visit.",
        "is_required": "Required",
        "drive_number": "Driver's License Number"
    },
    "order_now": {
        "customization": "Vehicle Customization",
        "next": "Next",
        "all_price": "Preselected Total",
        "remark": "The images used for the vehicle configuration above are for reference only; specific product details are subject to the final delivered car.",
        "submit_tip": "Congratulations! Customization submitted successfully, a staff member will contact you shortly!",
        "submit_tip_fail": "Submission failed, please complete the relevant information and submit again."
    }
}