//首页相关接口

import myaxios from "../MyAxios";
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const URL = URL_ENV.BASEURL

export default {

    /** 获取头部汽车品牌及下拉菜单
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": [
     *     {
     *       "id": 1,
     *       "brandLogo": null,
     *       "brandName": "ofady cn",
     *       "brandCar": [
     *         {
     *           "id": 1,
     *           "brandID": 1,
     *           "carName": "cn"
     *         }
     *       ]
     *     }
     *   ]
     * }
     * */
    queryHeaderBrand() {
        return myaxios.get(URL + 'brand', {});
    },
    /** 获取首页配置
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "index": null,
     *     "list_promotion": []
     *   }
     * }
     * */
    queryHomeIndex() {
        return myaxios.get(URL + 'home/index', {});
    },
    /** 获取首页友情链接，需要根据结果自动适配底部右下角链接
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "id": 0,
     *     "ytLinkUrl": null,
     *     "fbLinkUrl": null,
     *     "twLinkUrl": null,
     *     "ttLinkUrl": null,
     *     "insLinkUrl": null,
     *     "ldLinkUrl": null
     *   }
     * }
     * */
    queryHomeLink() {
        return myaxios.get(URL + 'common/link', {});
    }
}