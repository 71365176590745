<template>
  <div class="home" :style="`overflow-x:${isPc ? '' : 'hidden'}`">
    <!--    <Header :key="$store.state.HeaderKey"/>-->
    <HeaderOfady/>
    <keep-alive include="IndexHY">
      <router-view></router-view>
    </keep-alive>
    <div class="chat-us" :style="`height:${isPc?'':'60px'};width:${isPc?'':'60px'};right:${isPc?'':'10px'}`"><a
        :href="whatsAppHref" target="_blank"><i class="el-icon-chat-dot-round"></i></a></div>
    <!--    <FloatingWindow v-if="$route.name !== 'index'"/>-->
    <!--    <IndexHY/>-->
    <Footer/>
  </div>
</template>

<script>
import HeaderOfady from "@/components/HeaderOfady.vue"
import Footer from "@/components/Footer.vue"
import IndexHY from "@/views/IndexHY.vue"
import httpApi from "@/http";

export default {
    name: "HomeView",
    components: {Footer, HeaderOfady, IndexHY},
    data() {
        return {
            isPc: true,
            whatsAppHref: ''
        }
    },
    methods: {
        queryChatConfig() {
            httpApi.homeApi.queryHomeIndex().then(res => {
                if (res.data.code === 200) {
          let data = res.data.data;
          this.indexInfo = this._.get(data, 'index', {});
          let appSecretKey = this._.get(this.indexInfo, 'appSecretKey', '');
          let appLinkCode = this._.get(this.indexInfo, 'appLinkCode', '');
          if (appSecretKey && appLinkCode) {
            this.whatsAppHref = "https://api.whatsapp.com/send?phone=" + appLinkCode + "&text=" + appSecretKey
            if (!this.isPc) {
              this.whatsAppHref = "whatsapp://send?phone=" + appLinkCode + "&text=" + appSecretKey;
            }
          }
        }
      });
    }
  },
  mounted() {
    if (window.innerWidth < 800) {
      this.isPc = false;
    }
    this.queryChatConfig();
  }
}
</script>
<style>
.chat-us {
  position: fixed;
  width: 80px;
  bottom: 20%;
  right: 20px;
  z-index: 2222;
  cursor: pointer;
  height: 80px;
  border-radius: 100%;
  background-color: #3470c5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-us i {
  font-size: 40px;
  color: #fff;
}

.ofady .el-submenu__title {
  font-size: 0.875rem;
}
</style>
