//招商加盟和售后服务相关接口

import myaxios from "../MyAxios";
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const URL = URL_ENV.BASEURL

export default {

    /** 获取售后服务详情
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "list_service": [],
     *     "detail": {
     *       "id": 0,
     *       "countryId": 0,
     *       "countryName": null,
     *       "serviceDetail": null
     *     }
     *   }
     * }
     * */
    querySaleService(id) {
        return myaxios.get(URL + 'saleservice/index', {countryId: id});
    },
    /** 获取招商加盟首页
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "id": 0,
     *     "countryId": 0,
     *     "countryName": null,
     *     "joinDetail": null
     *   }
     * }
     * */
    queryJoinUs(id) {
        return myaxios.get(URL + 'join/index', {countryId: id});
    },
    /** 申请加盟
     * 参数列表
     * {
     *   "id": 0,
     *   "companyName": "string",
     *   "companyIndustry": "string",
     *   "joinMoney": 0,
     *   "joinMoneyCode": "string",
     *   "contactPersonName": "string",
     *   "contactPhone": "string",
     *   "contactEmail": "string",
     *   "countryId": 0,
     *   "countryName": "string",
     *   "provinceId": 0,
     *   "provinceName": "string",
     *   "cityId": 0,
     *   "cityName": "string",
     *   "addressDetail": "string",
     *   "createTime": "2023-07-23T08:57:48.931Z",
     *   "checkStatus": 0,
     *   "checkRemark": "string"
     * }
     * */
    submitJoinUs(params) {
        return myaxios.post(URL + 'join/add', params);
    }

}