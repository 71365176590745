import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "@/views/HomeView.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/home/indexHY",
    },
    {
        path: "/home",
        name: "home",
        component: HomeView,
        children: [
            {
                path: "indexHY",
                name: "indexHY",
                component: () => import("@/views/IndexHY.vue"),
            },
            {
                path: "support",
                name: "/support",
                component: () => import("@/views/footerPage/Support"),
                props: true,
            },
            {
                path: "policy",
                name: "/policy",
                component: () => import("@/views/footerPage/PrivacyPolicy"),
                props: true,
            },
            {
                path: "contact",
                name: "/contact",
                component: () => import("@/views/footerPage/Contact"),
                props: true,
            },
            {
                path: "termsOfUse",
                name: "/termsOfUse",
                component: () => import("@/views/footerPage/TermsOfUse"),
                props: true,
            },
            {
                path: "subscribe",
                name: "/subscribe",
                component: () => import("@/views/subscribe/Subscribe"),
                props: true,
            },
            {
                path: "orderNow/:wid/:cname",
                name: "/orderNow",
                component: () => import("@/views/OrderNow"),
            },
            {
                path: "car-detail/:model_id/:Cid",
                name: "/cardetail",
                component: () => import("@/views/detail/CarDetail"),
            },
            {
                path: "foal-detail/:model_id/:Cid",
                name: "/foalDetail",
                component: () => import("@/views/detail/FoalDetail"),
            },
            {
                path: "ofady-detail/:model_id/:Cid",
                name: "/ofadyDetail",
                component: () => import("@/views/detail/OfadyDetail"),
            },
            {
                path: "question",
                name: "/question",
                component: () => import("@/views/question/Question"),
            },
            {
                path: "order/submit",
                name: "/orderSubmit",
                component: () => import("@/views/Order/OrderSubmit"),
                props: true,
            },
            {
                path: "test-drive",
                name: "/test-drive",
                component: () => import("@/views/Testdrive/TestDrive"),
                props: true,
            },
            // headerTop相关(品牌中心)
            {
                path: "brandRelated",
                name: "/brandRelated",
                component: () =>
                    import("@/views/headerTop/brandCenter/brandRelated"),
            },
            // 售后
            {
                path: "afterSales",
                name: "/afterSales",
                component: () => import("@/views/afterSales/AfterSales"),
            },
            // 售后服务
            {
                path: "service",
                name: "/service",
                component: () => import("@/views/serviceCenter/ServiceCenter"),
            },
            // 招商加盟
            {
                path: "join",
                name: "/join",
                component: () => import("@/views/join/JoinUs"),
            },
            // 经销商
            {
                path: "distributor",
                name: "/distributor",
                component: () => import("@/views/distributor/Distributor"),
            },
            // 周边商城
            {
                path: "otherGoods",
                name: "/otherGoods",
                component: () => import("@/views/otherGoods/OtherGoods"),
            },
            // 周边商城
            {
                path: "otherGoodsDetail/:id",
                name: "/otherGoodsDetail",
                component: () => import("@/views/otherGoods/OtherGoodsDetail"),
            },
            // 活动列表
            {
                path: "activity",
                name: "/activity",
                component: () => import("@/views/activity/Activity"),
            },
            // 活动列表
            {
                path: "activityDetail",
                name: "/activityDetail",
                component: () => import("@/views/activity/ActivityDetail"),
            },
        ],
    },
]
const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
})

export default router
