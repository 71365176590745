//通用接口，各类banner图，富文本，常见问题，订阅我们

import myaxios from "../MyAxios";
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const URL = URL_ENV.BASEURL

export default {

    /**查询所有区域列表，根据areaPid查询子节点
     * 参数：areaPid表示获取某个国家，根据语言选择决定值
     *   {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": []
     * }
     * */
    queryArea(areaId) {
        return myaxios.get(URL + "common/area", {areaPid: areaId});
    },

    /**查询所有区域列表，用于生成地址下拉选择
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": [
     *     {
     *       "id": 1,
     *       "label": "Mexio",
     *       "children": []
     *     },
     *     {
     *       "id": 2,
     *       "label": "中国",
     *       "children": [
     *         {
     *           "id": 3,
     *           "label": "广东省",
     *           "children": [
     *             {
     *               "id": 4,
     *               "label": "深圳市",
     *               "children": [
     *                 {
     *                   "id": 6,
     *                   "label": "福田区",
     *                   "children": []
     *                 },
     *                 {
     *                   "id": 7,
     *                   "label": "南山区",
     *                   "children": []
     *                 }
     *               ]
     *             }
     *           ]
     *         }
     *       ]
     *     }
     *   ]
     * }
     * */
    queryAllArea() {
        return myaxios.get(URL + "common/area/treeselect", {});
    },

    /**查询各类banner图
     * 参数列表：bannerType：1：About FF 2：售后服务 3：经销商 4：招商加盟 5：周边商品 6：促销 7：订阅 8：隐私政策 9：联系我们 10：法律政策 11：技术支持 12：常见问题
     *
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": []
     * }
     * */
    queryBanner(type) {
        return myaxios.get(URL + "home/banner", {bannerType: type});
    },
    /**查询各类富文本，待有数据后再次确认
     * 参数列表：type：1：关于我们 2：隐私政策 3：联系我们 4：使用条款 5：网站支持
     *
     * {
     *   "code": 1,
     *   "msg": "FAIL"
     * }
     * */
    queryHtmlInfo(type) {
        return myaxios.get(URL + "common/info", {type: type});
    },
    /**查询问答列表
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "pageSize": 20,
     *     "pageIndex": 1,
     *     "totalNum": 0,
     *     "totalPage": 0,
     *     "result": [],
     *     "extra": {}
     *   }
     * }
     * */
    queryQuestionList() {
        return myaxios.get(URL + "common/question", {PageSize: 100});
    },
    /** 提交信息用于订阅平台
     * 参数列表如下：
     * {
     *   "id": 0,
     *   "countryID": 0,
     *   "countryName": "string",
     *   "name": "string",
     *   "email": "string",
     *   "phone": "string",
     *   "createTime": "2023-07-23T09:17:41.758Z"
     * }
     * */
    submitSubscribeUs(params) {
        return myaxios.post(URL + "common/subscribe", params);
    },

}