<template>
  <div>
    <el-row class="el-row-modelos el-row-white">
      <el-col :span="24">
        <div class="grid-content">{{ $t('footer.quick_link') }}</div>
      </el-col>
    </el-row>
    <div class="link-div" v-if="isPc">
      <div class="link-left">
        <!--活动列表-->
        <div class="link-one" @click="gotoQlink('/activity')">{{ $t('footer.activity_list') }} <i
            class="el-icon-d-arrow-right"></i></div>
        <!--车辆定制-->
        <div class="link-two" @click="gotoQlink('/otherGoods')">{{ $t('footer.other_goods') }} <i
            class="el-icon-d-arrow-right"></i></div>
      </div>
      <div class="link-right">
        <!--预约试驾-->
        <div class="link-one" @click="gotoQlink('/test-drive')">{{ $t('footer.drive_test') }} <i
            class="el-icon-d-arrow-right"></i>
        </div>
        <!--常见问题-->
        <div class="link-two" @click="goQuestion()">{{ $t('footer.question_list') }} <i
            class="el-icon-d-arrow-right"></i></div>

      </div>
    </div>
    <div class="link-div-phone" v-if="!isPc">
      <div class="link-one-phone" @click="gotoQlink('/activity')">{{ $t('footer.activity_list') }} <i
          class="el-icon-d-arrow-right"></i></div>
      <div class="link-one-phone" @click="gotoQlink('/otherGoods')">{{ $t('footer.other_goods') }} <i
          class="el-icon-d-arrow-right"></i></div>
      <div class="link-one-phone" @click="gotoQlink('/test-drive')">{{ $t('footer.drive_test') }} <i
          class="el-icon-d-arrow-right"></i></div>
      <div class="link-one-phone" @click="goQuestion()">{{ $t('footer.question_list') }} <i
          class="el-icon-d-arrow-right"></i></div>
    </div>
    <div class="footer">
      <el-backtop>
        <div class="up el-icon-arrow-up"></div>
      </el-backtop>
      <div class="footer_bottom" :style="`flex-direction:${isPc ? 'row' : 'column'}`">
        <div class="type">
          <div class="type_top">
            <router-link to="/home/policy">
              <a style="padding-right: 10px">{{ $t('footer.private_policy') }} </a>
            </router-link>
            |
            <router-link to="/home/contact">
              <a style="padding-right: 10px;padding-left: 10px"> {{ $t('footer.contact') }} </a>
            </router-link>
            |
            <router-link to="/home/termsOfUse">
              <a style="padding-right: 10px;padding-left: 10px"> {{ $t('footer.terms_use') }} </a>
            </router-link>
            |
            <router-link to="/home/support">
              <a style="padding-left: 10px"> {{ $t('footer.support') }} </a>
            </router-link>
          </div>
        </div>
        <div class="icon" :style="`flex-wrap:${isPc ? 'no-wrap' : 'wrap'}`">
            <!--          <span>{{ $t('footer.networks') }}</span>-->
            <!-- <a :href="fbLinkUrl" target="_blank" v-if="fbLinkUrl" title="facebook"
               :style="!isPc?'margin-top:0.5rem':''"><img src="../assets/facebook.png"
                                                          alt=""/></a> -->
            <a :href="insLinkUrl" target="_blank" v-if="insLinkUrl" title="instagram"
               :style="!isPc?'margin-top:0.5rem':''"><img src="../assets/instagram.png"
                                                          alt=""/></a>
            <!-- <a :href="twLinkUrl" target="_blank" v-if="twLinkUrl" title="twitter" :style="!isPc?'margin-top:0.5rem':''"><img
                src="../assets/twitter.png"
                alt=""/></a>
            <a :href="ytLinkUrl" target="_blank" v-if="ytLinkUrl" title="youtube" :style="!isPc?'margin-top:0.5rem':''"><img
                src="../assets/youtube.png"
                alt=""/></a> -->
            <a :href="ttLinkUrl" target="_blank" v-if="ttLinkUrl" title="tiktok"
               :style="!isPc?'margin-top:0.5rem':''"><img src="../assets/tiktok.png" alt=""/></a>
            <!-- <a :href="ldLinkUrl" target="_blank" v-if="ldLinkUrl" title="linkedin"
               :style="!isPc?'margin-right:0;margin-top:0.5rem':''"><img src="../assets/linkdin.png"
                                                                         alt=""/></a> -->
        </div>
      </div>
    </div>
    <div class="type_bottom">
      <span>{{ $t('footer.copyright') }}</span>
      <!--      <a> {{ $t('footer.copyright_number') }} </a>-->
    </div>
    <!--    <div class="accept-cookies-div">-->
    <el-drawer ref="accept-drawer"
               :custom-class="isPc?'accept-cookies-div':'accept-cookies-div-phone'"
               :visible.sync="showAccept"
               direction="btt"
               :with-header="false"
               :before-close="handleClose">
        <p :style="`width:${isPc ? '' : '100%'}`">{{ $t('footer.accept_cookies') }}
            <router-link to="/home/policy" target="_blank">
                <a style="padding-right: 30px">{{ $t('footer.private_policy') }} </a>
            </router-link>
        </p>
        <el-row style="text-align: right">
            <el-button type="info" @click="cookiesButtonClick(0)">{{ $t('footer.refuse') }}</el-button>
            <el-button class="my-green-button" type="success" @click="cookiesButtonClick(1)">{{
                    $t('footer.accept')
                }}
            </el-button>
        </el-row>
    </el-drawer>
    <!--    </div>-->
  </div>

</template>

<script>
import httpApi from "@/http";
import cache from "@/utils/cache";

export default {
  name: "Footer",
  data() {
    return {
      isPc: true,
      showAccept: false,
      acceptCookies: cache.getCache('acceptCookies'),
      BYD: ["下载用户手册", "经销商查询", "服务店查询", "充电桩查询"],
      help: ["经销商入口", "购买流程", "买家须知", "交易条款", "关于订单"],
      more: ["加入OFADY", "经销商加盟", "大客户业务"],
      follow: ["客户服务热线", "4008-303-666", "销售服务时间", "8:30-22:00"],
      types: [
        "PRIVACY POLICY",
        "CONTACT",
        "TERMS OF USE",
        "SUPPORT"
      ],
      "ytLinkUrl": "",
      "fbLinkUrl": "",
      "twLinkUrl": "",
      "ttLinkUrl": '',
      "insLinkUrl": '',
      "ldLinkUrl": ''
    }
  },
  methods: {
    gotoQlink(key) {
      this.$router.push({
        name: key
      }, () => {

      })
    },
    goQuestion() {
      this.$router.push({
        name: "/question"
      }, () => {

      })
    },
    queryHomeLink() {
      httpApi.homeApi.queryHomeLink().then(res => {
        if (res.data.code === 200) {
          let data = res.data.data;
          if (data) {
            this.ytLinkUrl = this._.get(data, 'ytLinkUrl', '');
            this.fbLinkUrl = this._.get(data, 'fbLinkUrl', '');
            this.twLinkUrl = this._.get(data, 'twLinkUrl', '');
            this.ttLinkUrl = this._.get(data, 'ttLinkUrl', '');
            this.insLinkUrl = this._.get(data, 'insLinkUrl', '');
            this.ldLinkUrl = this._.get(data, 'ldLinkUrl', '');
          }
        }
      });
    },
    handleClose(done) {
      done();
    },
    cookiesButtonClick(type) {
      if (type === 0) {
        this.acceptCookies = false;
        cache.setCache('acceptCookies', false);
      } else {
        this.acceptCookies = true;
        cache.setCache('acceptCookies', true);
      }
      this.$refs["accept-drawer"].closeDrawer();
    }
  },
  mounted() {
    if (window.innerWidth < 800) {
      this.isPc = false;
    }
    this.queryHomeLink();
    this.acceptCookies = this.acceptCookies ? this.acceptCookies : false;
    this.showAccept = !this.acceptCookies;
  }
}
</script>

<style lang="scss" scoped>
.el-row-white {
  background-color: #f7f7f7;
  padding: 1.5rem 0;
  color: #000;
  text-align: center;
}

.footer {
  position: relative;

  .el-backtop {
    position: absolute;
    top: -1.5625rem;
    left: 95%;
    width: 1.5625rem;
    height: 1.5625rem;

    .up {
        height: 100%;
        width: 100%;
        background-color: #25ab9b;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
        text-align: center;
        line-height: 1.5625rem;
        color: #fff;
    }
  }

  .footer_top {
    display: flex;
    justify-content: space-between;
    min-width: 893px;
    padding: 4.5rem 15.625rem 3.125rem 9.375rem;
    background-color: #25ab9b;

    > div {
      width: 12%;
      font-size: 0.875rem;

      p {
        color: #fff;
        padding-bottom: 0.625rem;
      }

      ul {
        li {
          font-size: 0.875rem;
          color: #fff;
          line-height: 1.875rem;

          a {
            text-decoration: none;
            color: #fff;
            line-height: 1.875rem;

            &:hover {
              color: #ffd04b;
            }
          }
        }
      }
    }

    .ewm {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 6.25rem;
        background-color: #fff;
        border-radius: 0.625rem;
      }

      span {
        color: #bdbdbd;
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
    }
  }

  .footer_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 2rem 0.625rem 2rem;
    background-color: #25ab9b;
    color: #1e897c;

    .type {
      .type_top {
        a {
          font-size: 0.875rem;
          color: #ffffff;
          line-height: 1.875rem;
          text-decoration: none;

          &:hover {
            color: #252728;
          }
        }
      }
    }

    .icon {
      //width: 20rem;
      display: flex;
      align-items: center;

      a {
        display: inline-block;
        padding: 0.625rem;
        border-radius: 50%;
        background-color: #252728;
        margin-right: 0.9375rem;
        cursor: pointer;

        img {
          width: 1.25rem;
          height: 1.25rem;
          display: block;
        }
      }

      span {
        font-size: 0.875rem;
        color: #ffffff;
        line-height: 1.875rem;
        text-decoration: none;
        margin-right: 0.675rem;
      }
    }
  }
}

.type_bottom {
  background-color: #25ab9b;
  color: #ffffff;
  padding: 40px;
  border-top: 1px solid #1e897c;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 1.25rem;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }

  a {
    font-size: 0.875rem;
    color: #ffffff;
  }
}

.link-div {
  display: flex;

  .link-left, .link-right {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 2rem;
    text-align: right;

    .link-one, .link-two {
      margin-bottom: 20px;
      width: 400px;
      display: inline-flex;
      cursor: pointer;
    }
  }

  .link-left {
    .link-one, .link-two {
      align-self: end;
      justify-content: space-between;
      margin-right: 10%;
    }
  }

  .link-right {
    .link-one, .link-two {
      align-self: start;
      justify-content: space-between;
      margin-left: 10%;
    }
  }

  .link-one:hover, .link-two:hover {
    color: #409EFF;
  }
}

.link-one-phone {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
<style lang="scss">
.accept-cookies-div {
    position: fixed !important;
    height: auto !important;
    width: 100%;
    bottom: 0;
    left: auto;
    padding: 38px 58px 48px !important;
    background-color: rgba(37, 171, 155, 0.85) !important;
    color: #fff !important;;
    z-index: 1999 !important;;

    p {
        line-height: 1.8 !important;
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  button {
    font-size: 20px;
    margin-top: 30px;
    margin-right: 30px;
    padding: 10px 50px;
  }
}

.accept-cookies-div-phone {
    position: fixed !important;
    height: auto !important;
    width: 100%;
    bottom: 0;
    left: auto;
    padding: 5%;
    background-color: rgba(37, 171, 155, 0.85) !important;;
    color: #fff !important;;
    z-index: 1999 !important;;

    p {
        line-height: 1 !important;;
        font-size: 18px !important;;
    font-weight: 400 !important;;
    padding-bottom: 60px;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  .el-row {
    position: absolute;
    bottom: 20px;
    display: flex;
    width: 90%;
    justify-content: space-between;

    button {
      font-size: 20px;
      margin-top: 10px;
      margin-right: 10px;
      padding: 10px 20px;
    }
  }

}
</style>
