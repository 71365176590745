import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import i18n from "@/lang";
import _ from 'lodash';

Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App),
    beforeCreate() {
        Vue.prototype.$bus = this;
        Vue.prototype._ = _;
    },
}).$mount('#app')
