export default {
    "index": {
        "drive_test": "Reservar prueba",
        "know_him": "Detalles de Parámetros",
        "modelos": "Modelo de vehículo",
        "view_more": "Más detalles",
        "why_ff": "Razones para elegir FF",
        "other_goods": "Merchandising",
        "subscribe": "Suscríbete a nuestro boletín"
    },
    "header": {
        "about_ff": "Acerca de nosotros",
        "service_workshops": "Servicio posventa",
        "find_distributor": "Distribuidores",
        "join_us": "Quiero ser distribuidor",
        "distributor": "Distribuidores",
        "other_goods": "Merchandising",
        "area_lang": "Región/Idioma"
    },
    "footer": {
        "quick_link": "Enlaces rápidos",
        "activity_list": "Promociones",
        "other_goods": "Merchandising",
        "drive_test": "Reservar prueba",
        "question_list": "Preguntas frecuentes",
        "private_policy": "Política de privacidad",
        "contact": "Contáctanos",
        "terms_use": "Términos de uso",
        "support": "Soporte técnico",
        "networks": "Red",
        "copyright": "Copyright@foalcar",
        "copyright_number": "ICP de Guangdong No. 10216027",
        "accept_cookies": "Utilizamos nuestras propias y terceras cookies para garantizar el correcto funcionamiento del sitio web y sus plug - Ins. Si continúa accediendo, acepte el contexto anterior. Para más información, consulte nuestra política de privacidad.",
        "accept": "Aceptar",
        "refuse": "Rechazar"
    },
    "activity": {
        "activity_center": "Promociones"
    },
    "car": {
        "guiding_price": "Precio de referencia",
        "view_360": "Vista 360° del vehículo",
        "view_drag_mouse": "Arrastre el ratón para ver",
        "interior": "Interior",
        "exterior": "Exterior",
        "view_tip": "Esta sección es solo para fines de visualización de contenido; la configuración real del modelo del vehículo está sujeta al vehículo real entregado.",
        "order_now": "Personalización inmediata"
    },
    "distributor": {
        "distributor_list": "Lista de distribuidores FOAL"
    },
    "join_us": {
        "joining_conditions": "Condiciones de franquicia",
        "joining_process": "Proceso de franquicia",
        "contact_us": "Contáctenos",
        "contact_tel": "Línea directa de contacto",
        "contact_email": "Correo electrónico de contacto",
        "address_detail": "Dirección",
        "tip_address": "Por favor ingrese la dirección detallada",
        "company_name": "Empresa",
        "tip_companyName": "Ingrese el nombre de la empresa",
        "company_industry": "Industria",
        "tip_companyIndustry": "Ingrese la industria a la que pertenece",
        "join_money": "Monto de la franquicia",
        "select_money": "Seleccione tipo de moneda",
        "tip_money": "Ingrese el monto de la franquicia",
        "cancel": "Cancelar",
        "confirm": "Confirmar",
        "loading": "Enviando...",
        "join_us": "Solicitud de franquicia",
        "submit_success": "Envío exitoso, nos pondremos en contacto con usted lo antes posible."
    },
    "order": {
        "confirm_information": "Confirmar información",
        "name": "Apellido",
        "phone": "Número de teléfono móvil",
        "email": "Correo electrónico",
        "selected_customization": "Configuración personalizada seleccionada",
        "tip": "Las imágenes utilizadas para la información de configuración del vehículo anterior son solo de referencia; los detalles específicos del producto están sujetos al vehículo real entregado.",
        "selected_area": "Provincia/Municipio/Distribuidores",
        "area": "Región",
        "distributor": "Distribuidores",
        "please_select": "Seleccione",
        "car_information": "Información del producto",
        "car_selected_price": "Monto preseleccionado",
        "agree": "Agrego y acepto",
        "agree_message": "Notas de pedido",
        "change_info": "Modificar configuración",
        "submit_info": "Enviar configuración",
        "tip_name": "Ingrese su nombre",
        "tip_phone": "Ingrese su número de teléfono móvil",
        "tip_number": "Ingrese un número",
        "tip_email": "Ingrese su correo electrónico"
    },
    "other_goods": {
        "country": "País",
        "select_country": "Seleccione un país",
        "mx": "México",
        "zh": "China",
        "am": "Estados Unidos",
        "name": "Apellido",
        "phone": "Número de teléfono",
        "email": "Correo electrónico",
        "click_now": "Haga clic para obtener más información",
        "detail": "Detalles",
        "submit_success": "¡Felicidades! Se ha enviado con éxito; uno de nuestros asesores se pondrá en contacto lo más pronto posible."
    },
    "service_center": {
        "list": "Lista de servicios postventa FOAL"
    },
    "subscribe": {
        "title": "Hemos determinado tu ubicacion actual",
        "remark": "Hemos determinado tu ubicacion actual",
        "confirm_subscribe": "Enviar suscripción",
        "submit_success": "¡Suscripción exitosa!"
    },
    "drive_test": {
        "drive_test": "Reservar prueba",
        "tip": "Bienvenido a la reservación de una prueba de manejo en línea. Completa la siguiente información para brindarte una mejor experiencia de manejo.",
        "select_info": "Selecciona la información para tu prueba de manejo:",
        "type": "Modelo:",
        "select_date": "Horario de prueba:",
        "select_date_tip": "Selecciona una fecha:",
        "buyer_info": "Completa la información de contacto:",
        "agree": "He leído y acepto la política de privacidad",
        "confirm_drive": "Solicitar una prueba de manejo",
        "submit_success": "La información de prueba se ha agregado con éxito; esperamos tu visita.",
        "is_required": "Campos obligatorios",
        "drive_number": "Número de licencia de conducir"
    },
    "order_now": {
        "customization": "Personalización del vehículo",
        "next": "Siguiente paso",
        "all_price": "Total preseleccionado",
        "remark": "Las imágenes utilizadas para la información de configuración del vehículo son solo de referencia, los detalles específicos del producto están sujetos al vehículo real entregado.",
        "submit_tip": "¡Felicidades! La personalización se ha enviado con éxito, uno de nuestros asesores se pondrá en contacto lo antes posible.",
        "submit_tip_fail": "Envío fallido, complete la información y vuelva a enviarla."
    }
}