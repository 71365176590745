<template>
    <div class="home" style="margin-top: 4.5rem;height: 100%;">
        <div class="banner" id="0hy" :style="isPc?'height: calc(100vh - 4.5rem);':''">
            <video id="videoShow"
                   :src="indexInfo.videoUrl"
                   autoplay loop="loop" muted
            ></video>
            <div class="open-audio-div">
                <i :class="isOpenAudio?'el-icon-bell':'el-icon-close-notification'" @click="openAudio"></i>
            </div>
            <!-- <el-row align="center" justify="center" class="button-div-center">
                <el-button @click="goTestDrive(indexInfo.id)" class="my-button"
                           :style="!isPc?'margin-left:0;margin-top:5px;':''">{{ $t('index.drive_test') }}
                </el-button>
                <el-button @click="goHim()" class="my-button" :style="!isPc?'margin-left:0;margin-top:5px;':''">
                    {{ $t('index.know_him') }}
                </el-button>
            </el-row> -->
        </div>

        <el-row class="el-row-modelos">
            <el-col :span="24">
                <div class="grid-content">{{ $t('index.modelos') }}</div>
            </el-col>
        </el-row>
        <el-carousel indicator-position="outside" :height="autoCarouselHeight">
            <!--todo 待优化逻辑-->
            <el-carousel-item v-for="carousel in carouselLength" :key="carousel" v-if="isPc">
                <div v-for="item in carList"
                     class="banner-list"
                     :key="item.id"
                     :id="item.id + item.brandID" :style="`width:${isPc ? '25%' : '100%'}`"
                >
                    <img :src="item.carMainPhotoUrl" alt=""/>
                    <div class="car-name">{{ item.brandName }}</div>
                    <div class="car-desc">{{ item.carName }}</div>
                    <!--          <div class="car-price">$10,000,000</div>-->
                    <div class="ban_text" @click="goCarDetail(item.brandID, item.id)">{{ $t('index.view_more') }} >
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item v-for="item in carList" v-if="!isPc">
                <div
                    class="banner-list"
                    :key="item.id"
                    :id="item.id + item.brandID" style="width:100%"
                >
                    <img :src="item.carMainPhotoUrl" alt=""/>
                    <div class="car-name">{{ item.brandName }}</div>
                    <div class="car-desc">{{ item.carName }}</div>
                    <!--          <div class="car-price">$10,000,000</div>-->
                    <div class="ban_text" @click="goCarDetail(item.brandID, item.id)">{{ $t('index.view_more') }} >
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="banner why" :style="`flex-direction:${isPc ? '' : 'column'}`">
            <div class="why-div"
                 :style="`width:${isPc ? '50%' : '100%'};margin-left:${isPc?'':'0'};margin-right:${isPc?'':'0'}`">
                <div class="why-title">{{ $t('index.why_ff') }}</div>
                <img :src="indexInfo.aboutImageUrlOne"/>
                <div class="desc-div-bottom">
                    <div class="desc-one">{{ indexInfo.aboutContentTitleOne }}</div>
                    <div class="desc-detail">{{ indexInfo.aboutContentOne }}</div>
                </div>
            </div>
            <div class="why-div"
                 :style="`width:${isPc ? '50%' : '100%'};margin-left:${isPc?'':'0'};margin-right:${isPc?'':'0'}`">
                <div class="why-title">{{ $t('index.why_ff') }}</div>
                <img :src="indexInfo.aboutImageUrlTwo"/>
                <div class="desc-div-bottom">
                    <div class="desc-one">{{ indexInfo.aboutContentTitleTwo }}</div>
                    <div class="desc-detail">{{ indexInfo.aboutContentTwo }}</div>
                </div>
            </div>
        </div>
        <div class="promacion-div" :style="`flex-direction:${isPc ? '' : 'column'}`">
            <div :class="index===0?'promacion-one':'promacion-two'" v-for="(p,index) in promotionList"
                 :style="`width:${isPc ? '50%' : '100%'}`">
                <img :src="p.photo" alt=""/>
                <div class="promacion-desc">
                    <div class="name">{{ p.title }}</div>
                    <!--          <div class="desc">Totalmente Electrico</div>-->
                    <!--          <div class="desc">Promoción</div>-->
                </div>
                <div class="detail my-button" @click="gotoActivity(p.id)">{{ $t('index.view_more') }}</div>
            </div>
        </div>
        <div class="sub-div" :style="`flex-direction:${isPc ? '' : 'column'}`">
            <div class="sub-one" :style="`width:${isPc ? '40%' : '100%'}`">
                <img :src="indexInfo.otherImageUrl" alt="" :style="`min-height:${isPc ? '500px' : '300px'}`"/>
                <div class="sub-button">
                    <router-link to="/home/otherGoods">
                        <el-button class="my-button">{{ $t('index.other_goods') }}</el-button>
                    </router-link>
                </div>
            </div>
            <div class="sub-two" :style="`width:${isPc ? '60%' : '100%'}`">
                <img :src="indexInfo.subscribeImageUrl" alt="" :style="`min-height:${isPc ? '500px' : '300px'}`"/>
                <div class="sub-button">
                    <el-button @click="goSubscribe()" class="my-button">{{ $t('index.subscribe') }}</el-button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import httpApi from "@/http";
import cache from "@/utils/cache";

export default {
    name: "IndexHY",
    data() {
        return {
            isPc: true,
            lang: cache.getCache('LANGUAGE'),
            indexInfo: {},
            promotionList: [],
            carList: [],
            carouselLength: 1,
            isOpenAudio: false,
            autoCarouselHeight: '400px'
        }
    },
    methods: {
        queryIndexInfo() {
            httpApi.homeApi.queryHomeIndex().then(res => {
                if (res.data.code === 200) {
                    let data = res.data.data;
                    this.indexInfo = this._.get(data, 'index', {});
                    if (!this.isPc) {
                        this.indexInfo.aboutImageUrlOne = this.indexInfo.aboutImagePhoneUrlOne;
                        this.indexInfo.aboutImageUrlTwo = this.indexInfo.aboutImagePhoneUrlTwo;
                        this.indexInfo.subscribeImageUrl = this.indexInfo.subscribeImagePhoneUrl;
                        this.indexInfo.otherImageUrl = this.indexInfo.otherImagePhoneUrl;
                    }
                    this.promotionList = data['list_promotion'];
                }
            });
        },
        queryOnSaleCar() {
            httpApi.carApi.queryOnSaleCar().then(res => {
                if (res.data.code === 200) {
                    this.carList = res.data.data;
                    if (this.isPc) {
                        this.carouselLength = Math.ceil(this.carList.length / 4);
                    } else {
                        this.carouselLength = this.carList.length;
                    }
                }
            });
        },
        // 点击立即订购
        goOrderNow(id, cname) {
            if (id === 5) {
                this.$router.push({
                    name: "/cardetail",
                    params: {model_id: 15, Cid: id},
                })
                return
            }
            this.$router.push({name: "/orderNow", params: {wid: id, cname}})
        },
        goSubscribe() {
            this.$router.push({
                name: "/subscribe"
            }, () => {

            })
        },
        goQuestion() {
            this.$router.push({
                name: "/question"
            }, () => {

            })
        },
        // 点击预约试驾
        goTestDrive(cname) {
            this.$router.push({name: "/test-drive", params: {cname}})
        },
        // 点击预约试驾
        gotoActivity(id) {
            this.$router.push({name: "/activityDetail", params: {id}})
        },
        // 点击参数详情(车辆详情)
        goHim() {
            let model_id = 2, Cid = this.indexInfo.id;
            this.$router.push({name: "/cardetail", params: {model_id, Cid}})
        },
        // 点击车辆详情(车辆详情)
        goCarDetail(model_id, Cid) {
            let brandSortList = this._.sortedUniq(this._.map(this.carList, 'brandID'));
            let type = 'foal';
            if (this._.findIndex(brandSortList, function (b) {
                return b == model_id;
            }) === 0) {
                type = "foal";
            } else {
                type = 'ofady';
            }
            if (type === 'foal') {
                this.$router.push(
                    {name: "/foalDetail", params: {model_id, Cid: Cid}},
                    () => {
                    }
                )
                if (this.$route.name == "/foalDetail") this.$router.go(0)
            } else {
                this.$router.push(
                    {name: "/ofadyDetail", params: {model_id, Cid: Cid}},
                    () => {
                    }
                )
                if (this.$route.name == "/ofadyDetail") this.$router.go(0)
            }
        },
        openAudio() {
            let video = document.getElementById('videoShow');
            if (video) {
                video.muted = !video.muted;
                this.isOpenAudio = !this.isOpenAudio;
            }
        }
    },
    mounted() {
        if (window.innerWidth > 2400) {
            this.autoCarouselHeight = '500px';
        }
        if (window.innerWidth < 2300) {
            this.autoCarouselHeight = '430px';
        }
        if (window.innerWidth < 2200) {
            this.autoCarouselHeight = '420px';
        }
        if (window.innerWidth < 2100) {
            this.autoCarouselHeight = '410px';
        }
        if (window.innerWidth < 2000) {
            this.autoCarouselHeight = '400px';
        }
        if (window.innerWidth < 1700) {
            this.autoCarouselHeight = '360px';
        }
        if (window.innerWidth < 1600) {
            this.autoCarouselHeight = '350px';
        }
        if (window.innerWidth < 1500) {
            this.autoCarouselHeight = '340px';
        }
        if (window.innerWidth < 1400) {
            this.autoCarouselHeight = '330px';
        }
        if (window.innerWidth < 1300) {
            this.autoCarouselHeight = '320px';
        }
        if (window.innerWidth < 1200) {
            this.autoCarouselHeight = '300px';
        }
        if (window.innerWidth < 800) {
            this.isPc = false;
            this.autoCarouselHeight = '340px'
        }
        console.log(this.autoCarouselHeight)
        this.queryIndexInfo();
        this.queryOnSaleCar();
    },
    watch: {
        $route(to, from) {
            console.log('to.path: ' + to.name);
            if (to.name === "indexHY") {
                let video = document.getElementById('videoShow');
                if (video) {
                    video.currentTime = 0;
                    video.play();
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    position: relative;
    width: 100%;

    video {
        display: block;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    img {
        display: block;
        width: 100%;
    }

    .button-div-center {
        position: absolute;
        width: 100%;
        background: none;
        bottom: 20px;
    }

    .open-audio-div {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 20px;
        right: 20px;
    }

    .ban_msg {
        position: absolute;
        top: 85%;
        width: 100%;
        text-align: center;
        z-index: 10;

        span {
            background-color: rgba(255, 255, 255, 0.5);
            margin: 0 0.625rem;
            padding: 0.125rem 0.625rem;
            border-radius: 1.5625rem;
            font-size: 0.75rem;
            color: rgb(87, 86, 86);
            cursor: pointer;
            user-select: none;

            &:hover {
                background-color: #fff;
            }
        }
    }

    .desc-div-top, .desc-div-bottom {
        position: absolute;
        width: 100%;
        color: #fff;
        text-align: center;

        .desc-one {
            font-size: 24px;
        }

        .desc-detail {
            padding-top: 10px;
        }
    }

    .desc-div-top {
        top: 20px;
    }

    .desc-div-bottom {
        bottom: 20px;
    }
}

.banner-list {
    display: inline-block;
    width: 25%;
    padding: 10px;

    img {
        display: block;
        width: 100%;
        //height: 300px;
    }

    .car-name {
        padding-top: 5px;
    }

    .ban_text {
        font-size: 20px;
        cursor: pointer;
        color: #409EFF;
    }
}

.el-row {
    text-align: center;

    .el-button {
        margin-right: 1.625rem;
    }
}

.why {
    display: flex;

    .why-div {
        margin: 1rem 0.625rem;
        background-color: #f7f7f7;
        width: 50%;

        .why-title {
            padding: 1rem 0;
            text-align: center;
        }

        .desc-div-bottom {
            position: relative;
            color: #000;
            bottom: 0;
            text-align: left;
            padding: 1rem;
        }
    }
}

.el-row-modelos {
    background-color: #25ab9b;
    padding: 1.5rem 0;
    color: #fff;
}

.el-row-white {
    background-color: #f7f7f7;
    padding: 1.5rem 0;
    color: #000;
}

.promacion-div {
    display: flex;
    padding-top: 2rem;

    .promacion-one, .promacion-two {
        position: relative;
        padding: 1rem 0.625rem;
        width: 50%;
        color: #fff;

        img {
            display: block;
            width: 100%;
            min-height: 300px;
        }

        .promacion-desc {
            position: absolute;
            bottom: 20%;
            background: none;
            color: #fff;
            border-radius: 10px;
            padding: 10px;

            .name {
                padding-bottom: 10px;
            }

            .desc {
                padding-bottom: 10px;
            }

        }

        .detail {
            position: absolute;
            bottom: 10%;
            cursor: pointer;
            line-height: 1;
        }
    }

    .promacion-one {
        .promacion-desc, .detail {
            left: 30px;
        }
    }

    .promacion-two {
        .promacion-desc, .detail {
            right: 30px;
        }
    }
}

.sub-div {
    display: flex;
    padding: 1rem 0;

    .sub-one {
        position: relative;
        width: 40%;
        padding: 1rem 0.625rem;

        img {
            display: block;
            width: 100%;
            height: 100%;
            min-height: 550px;
        }

        .sub-button {
            position: absolute;
            display: inline-block;
            bottom: 40px;
            left: 40%;
        }
    }

    .sub-two {
        position: relative;
        width: 60%;
        height: 100%;
        padding: 1rem 0.625rem;

        img {
            display: block;
            width: 100%;
            min-height: 550px;
        }

        .sub-button {
            position: absolute;
            display: inline-block;
            bottom: 40px;
            right: 40%;
        }
    }
}

.link-div {
    display: flex;

    .link-left, .link-right {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 2rem;
        text-align: right;

        .link-one, .link-two {
            margin-bottom: 20px;
            width: 400px;
            display: inline-flex;
            cursor: pointer;
        }
    }

    .link-left {
        .link-one, .link-two {
            align-self: end;
            justify-content: space-between;
            margin-right: 10%;
        }
    }

    .link-right {
        .link-one, .link-two {
            align-self: start;
            justify-content: space-between;
            margin-left: 10%;
        }
    }

    .link-one:hover, .link-two:hover {
        color: #409EFF;
    }
}

.my-button {
    background: none;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    padding: 12px 24px;
    min-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 0;
    border: 1px solid #ffffff;
    transition: background-color 0.4s ease-out, width 0.4s ease-out;
    line-height: 1.5;

    &:hover {
        background: #ffffff;
        color: #252728;
    }
}
</style>
