// BaseUrl.js 封装请求前缀 方便切换

const URL_ENV = {
    DEV: {
        // 封装开发环境的URL  DEVELOPMENT
        // BMDURL: "http://127.0.0.1:3000",
        BMDURL: "http://114.116.45.82:3000",
        // UPLOADURL: "http://localhost:9000",
    },
    PRO: {
        // 封装生产环境的URL
        // BMDURL: "https://web.codeboy.com/bmdapi",
        // UPLOADURL: "https://web.codeboy.com/bmduploadapi",
    },
    BASEURL: process.env.NODE_ENV === 'development' ? 'http://api.foalcar.com/' : '/api/'
}

export default URL_ENV;
