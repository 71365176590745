import Vue from "vue";
import VueI18n from "vue-i18n";
import zhCN from "./zh-CN";
import en from "./en";
import sp from "./sp";
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import esLocale from 'element-ui/lib/locale/lang/es'

Vue.use(VueI18n);
const i18n = new VueI18n({
    legacy: false,
    locale: getLanguage(),
    messages: {
        'CN': {
            ...zhCN,
            ...zhLocale
        },
        'EN': {
            ...en,
            ...enLocale
        },
        'SP': {
            ...sp,
            ...esLocale
        },
        'zh-cn': {
            ...zhCN,
            ...zhLocale
        },
        'en': {
            ...en,
            ...enLocale
        },
        'sp': {
            ...sp,
            ...esLocale
        },
        'es': {
            ...sp,
            ...esLocale
        },
    }
})
export default i18n

import cache from "@/utils/cache";

export function getLanguage() {
    const chooseLanguage = cache.getCache('LANGUAGE')
    if (chooseLanguage) return chooseLanguage

    // 如果没有选择语言
    const language = navigator.language.toLowerCase()
    const locales = ['en', 'sp', 'zh-cn', 'es']
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            const nowHref = window.location.href;
            const nowCountryId = cache.getCache('COUNTRYID');
            if (nowCountryId === '') {
                if (nowHref.indexOf('www.foalcar.com') > -1) {
                    /*中国网址*/
                    cache.setCache('COUNTRYID', 2);
                    if (locale === 'sp' || locale === 'es') {
                        cache.setCache('LANGUAGE', 'SP');
                    } else if (locale === 'zh-cn') {
                        cache.setCache('LANGUAGE', 'CN');
                    }

                } else {
                    /*墨西哥网址*/
                    cache.setCache('COUNTRYID', 1);
                    if (locale === 'sp' || locale === 'es') {
                        cache.setCache('LANGUAGE', 'SP');
                    } else if (locale === 'zh-cn') {
                        cache.setCache('LANGUAGE', 'CN');
                    }
                }
            }

            return locale
        }
    }
    return 'zh-cn'
}

export function getLang() {
    const lang = getLanguage();
    let lastLang;
    switch (lang) {
        case 'en':
        case 'EN':
            lastLang = 'en';
            break
        case 'zh-cn':
        case 'zh-CN':
        case 'cn':
        case 'CN':
            lastLang = 'cn';
            break
        default:
            lastLang = 'es';
    }
    return lastLang;
}

export function changeI18nLanguage(lang) {
    cache.setCache('LANGUAGE', lang);
}