// 封装axios

import axios from 'axios'

const instance = axios.create()
import {getLang} from "@/lang";

const myaxios = {
    /**
     * 用于发送get请求
     * @param {string} url 请求路径
     * @param {object} params 请求参数对象{参数名:参数值}
     */
    get(url, params) {
        return instance({
            url,
            method: 'GET',
            params,
            headers: {
                Language: getLang()
            }
        })
    },
    /**
     * 用于发送post请求
     * @param {string} url 请求路径
     * @param {object} data 请求参数对象{参数名:参数值}
     */
    post(url, data) {
        return instance({
            url,
            method: "POST",
            data: data,
            headers: {
                Language: getLang()
            }
        })
    }
}

export default myaxios