//和所有汽车相关的接口

import myaxios from "../MyAxios";
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const URL = URL_ENV.BASEURL

export default {

    /**查询所有品牌和车型，用于生成网页内汽车选择下拉框内容
     *   {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": [
     *     {
     *       "id": 1,
     *       "brandID": 1,
     *       "brandName": "ofady cn",
     *       "carName": "cn"
     *     }
     *   ]
     * }
     * */
    queryAllCar() {
        return myaxios.get(URL + "car/select", {});
    },
    /**查询所有在售汽车列表，用于首页Models
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": [
     *     {
     *       "id": 1,
     *       "brandID": 1,
     *       "brandName": "ofady cn",
     *       "carName": "cn",
     *       "moneyCode": null,
     *       "minPrice": 0,
     *       "maxPrice": 0,
     *       "photoUrl": null,
     *       "photoTitle": null,
     *       "photoContent": null
     *     }
     *   ]
     * }
     * */
    queryOnSaleCar() {
        return myaxios.get(URL + "car/list", {});
    },
    /**根据汽车id查询详情
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "id": 1,
     *     "brandID": 1,
     *     "carName": "en",
     *     "carName_CN": "cn",
     *     "carName_SP": "sp",
     *     "moneyCode": null,
     *     "minPrice": 0,
     *     "maxPrice": 0,
     *     "carDetailFileUrl": null,
     *     "carDetailFileUrl_CN": null,
     *     "carDetailFileUrl_SP": null,
     *     "carBannerPhotoUrl": null,
     *     "carBannerVideoUrl": null,
     *     "carDetailPhotoUrl": null,
     *     "carDetailPhoneUrl": null,
     *     "carDetailOnePhotoUrl": null,
     *     "carDetailOnePhotoTitle": null,
     *     "carDetailOnePhotoTitle_CN": null,
     *     "carDetailOnePhotoTitle_SP": null,
     *     "carDetailOnePhotoContent": null,
     *     "carDetailOnePhotoContent_CN": null,
     *     "carDetailOnePhotoContent_SP": null,
     *     "carDetailFourPhotoTitle": null,
     *     "carDetailFourPhotoTitle_CN": null,
     *     "carDetailFourPhotoTitle_SP": null,
     *     "carDetailFourPhotoContent": null,
     *     "carDetailFourPhotoContent_CN": null,
     *     "carDetailFourPhotoContent_SP": null,
     *     "carDetailFourPhotoOneUrl": null,
     *     "carDetailFourPhotoTwoUrl": null,
     *     "carDetailFourPhotoThreeUrl": null,
     *     "carDetailFourPhotoFourUrl": null,
     *     "brand": {
     *       "id": 1,
     *       "brandLogo": null,
     *       "brandName": "ofady",
     *       "brandName_CN": "ofady cn",
     *       "brandName_SP": "ofady sp",
     *       "brandIntro": null,
     *       "brandIntro_CN": null,
     *       "brandIntro_SP": null,
     *       "createTime": null
     *     },
     *     "createTime": null,
     *     "isFullView": 0,
     *     "sort": 0,
     *     "carStatus": 2,
     *     "list_Photo": []
     *   }
     * }
     * */
    queryCarDetail(id) {
        return myaxios.get(URL + "car/" + id, {});
    },
    /**根据汽车id获取参数详情，用于Know HIM
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": ""
     * }
     * */
    queryCarParamsDetail(id) {
        return myaxios.get(URL + "car/" + id + "/item_detail", {});
    },
    /** 根据汽车id查询汽车可定制内容
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": [
     *     {
     *       "id": 1,
     *       "carID": 1,
     *       "carItemName": "颜色",
     *       "isChangePrice": 0,
     *       "isChangePhoto": 1,
     *       "list_Value": []
     *     }
     *   ]
     * }
     * */
    queryCarBookDetail(id) {
        return myaxios.get(URL + "car/" + id + "/item_list", {});
    },
    /** 提交某辆车的定制信息
     * 参数列表如下：
     * {
     *   "id": 0,
     *   "countryID": 0,
     *   "carID": 0,
     *   "distributorID": 0,
     *   "pickCarTime": "2023-07-23T03:59:35.381Z",
     *   "list_Item": [
     *     {
     *       "carItemID": 0,
     *       "carItemValueID": 0
     *     }
     *   ]
     * }
     * */
    submitBookCar(params) {
        return myaxios.post(URL + "car/book", params);
    },
    /** 提交某辆车的试驾
     * 参数列表如下：
     * {
     *   "id": 0,
     *   "carID": 0,
     *   "distributorID": 0,
     *   "testDriveTime": "2023-07-23T04:04:53.592Z",
     *   "linkName": "string",
     *   "linkTel": "string",
     *   "linkEmail": "string"
     * }
     * */
    submitTestDriveCar(params) {
        return myaxios.post(URL + "car/test_drive", params);
    },
    queryCarFullView(id) {
        return myaxios.get(URL + "car/" + id + "/fullview", {});
    }
}