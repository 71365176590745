// 存放所有立即订购相关接口

import myaxios from "../MyAxios"
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const BYDURL = URL_ENV.DEV.BMDURL // 使用普通的(还有一个上传文件的)

export default {
    // 通过类型id查询车型
    /**
     * @param {object} params
     * 参数列表{wid:1}
     */
    queryCarMessageByWcId(params) {
        return myaxios.get(BYDURL + "/v1/byd_car_message/query", params)
    },

    // 通过类型id查询车外观
    /**
     * @param {object} params
     * 参数列表{wid:1}
     */
    queryCarAppearanceByWcId(params) {
        return myaxios.get(BYDURL + "/v1/byd_car_appearance/query", params)
    },

    // 通过车型id查询车内饰信息
    /**
     * @param {object} params
     * 参数列表{mid:1}
     */
    queryCarInteriorByCMId(params) {
        return myaxios.get(BYDURL + "/v1/byd_car_interior/query", params)
    },

    // 查询选装信息
    queryCarOptional() {
        return myaxios.get(BYDURL + "/v1/byd_car_optional")
    },

    // 获取订购须知信息
    queryNotice() {
        return myaxios.get(BYDURL + "/v1/byd_car_notice")
    },
}
