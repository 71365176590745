//经销商相关接口

import myaxios from "../MyAxios";
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const URL = URL_ENV.BASEURL

export default {

    /** 获取经销商列表及详情
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": []
     * }
     * */
    queryDistributor(countryId) {
        return myaxios.get(URL + 'distributor/index', {countryId: countryId});
    }
}