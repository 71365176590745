//经销商相关接口

import myaxios from "../MyAxios";
import URL_ENV from "@/http/BaseUrl" // 引入基础url路径
const URL = URL_ENV.BASEURL

export default {

    /** 获取周边产品列表
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "pageSize": 20,
     *     "pageIndex": 1,
     *     "totalNum": 0,
     *     "totalPage": 0,
     *     "result": [],
     *     "extra": {}
     *   }
     * }
     * */
    queryProductList() {
        return myaxios.get(URL + 'product/list', {PageSize: 100});
    },
    /** 获取周边产品详情
     * {
     *   "code": 200,
     *   "msg": "SUCCESS",
     *   "data": {
     *     "id": 0,
     *     "productName": null,
     *     "productCode": null,
     *     "productPhotoUrl": null,
     *     "productPrice": 0,
     *     "moneyCode": null,
     *     "productDetail": null,
     *     "createTime": null,
     *     "status": 0
     *   }
     * }
     * */
    queryProductDetail(id) {
        return myaxios.get(URL + 'product/' + id, {});
    },
    /** 提交周边产品的预定信息
     * {
     *   "productID": 0,
     *   "countryID": 0,
     *   "linkName": "string",
     *   "linkEmail": "string",
     *   "linkTel": "string",
     *   "linkRemark": "string"
     * }
     * */
    submitBookProduct(params) {
        return myaxios.post(URL + 'product/book', params);
    },
}