<template>
    <div :style="`height:${isPc ? 0 : '4.5rem'}`" class="H">
        <header class="ofady" v-if="isPc">
            <router-link to="/home/indexHY">
                <!--        <img src="../assets/foalcar_logo_nobg.png" alt=""/>-->
                <img src="../assets/foalcar_logo.jpg" alt=""/>
            </router-link>
            <el-menu :mode="menuMode" background-color="#25ab9b"
                     text-color="#fff"
                     active-text-color="#fff">
                <el-submenu :index="b.id+'brand'" v-for="(b,index) in brandList">
                    <template slot="title" style="font-size: 0.875rem">{{ b.brandName }}</template>
                    <el-menu-item v-for="item in b.brandCar" :index="item.id.toString()"
                                  @click="goCarDetail(item.id,index,item.brandID)">
                        {{ item.carName }}
                    </el-menu-item>
                </el-submenu>
                <!--        <el-submenu index="2">
                          <template slot="title" style="font-size: 0.875rem">OFADY</template>
                          <el-menu-item v-for="item in ofady" :index="item.mid.toString()" @click="goOfadyCarDetail(item.mid)">
                            {{ item.name }}
                          </el-menu-item>
                        </el-submenu>-->
                <el-menu-item index="13" @click="$router.push({ name: '/brandRelated' })">{{ $t('header.about_ff') }}
                </el-menu-item>
                <!--进入售后服务中心，列表地图展示售后地点        -->
                <el-menu-item index="14" @click="$router.push({ name: '/service' })">{{
                        $t('header.service_workshops')
                    }}
                </el-menu-item>
                <el-submenu index="15">
                    <template slot="title" style="font-size: 0.875rem">{{ $t('header.find_distributor') }}</template>
                    <el-menu-item index="15_1" @click="$router.push({ name: '/join' })">{{
                            $t('header.join_us')
                        }}
                    </el-menu-item>
                    <el-menu-item index="15_2" @click="$router.push({ name: '/distributor' })">
                        {{ $t('header.distributor') }}
                    </el-menu-item>
                </el-submenu>
                <el-menu-item index="16" @click="$router.push({ name: '/otherGoods' })">{{ $t('header.other_goods') }}
                </el-menu-item>
            </el-menu>
            <div class="header_right">
                <div class="el-icon-location-outline">
                    <el-dropdown @command="changeCountry">
                        <span class="el-dropdown-link">{{ nowCountry }}</span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="c.value" v-for="c in countryList" :key="c.value">{{
                                    c.label
                                }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown @command="changeLanguage">
                        <span class="el-dropdown-link">{{ nowLanguage }}<i
                            class="el-icon-arrow-down el-icon--right"></i></span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="Español">Español</el-dropdown-item>
                            <el-dropdown-item command="中文">中文</el-dropdown-item>
                            <el-dropdown-item command="English">English</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!--        <div class="el-icon-view" @click="isShow" :title="headerTop?'展开头部':'隐藏头部'"></div>-->
            </div>
        </header>
        <div v-if="!isPc" class="phone-header">
            <div class="phone-header-div">
                <router-link to="/home/indexHY">
                    <img src="../assets/foalcar_logo.jpg" alt=""/>
                </router-link>
                <span class="el-icon-menu" style="font-size: 40px;margin-right: 10px" @click="showMenu"></span>
            </div>
            <el-drawer
                :visible.sync="showAllMenu"
                :with-header="false"
                direction="ltr"
                size="80%">
                <el-menu :mode="menuMode"
                         active-text-color="#ffd04b">
                    <el-submenu :index="b.id+'brand'" v-for="(b,index) in brandList">
                        <template slot="title" style="font-size: 0.875rem">{{ b.brandName }}</template>
                        <el-menu-item v-for="item in b.brandCar" :index="item.id.toString()"
                                      @click="goCarDetail(item.id,index,item.brandID)">
                            {{ item.carName }}
                        </el-menu-item>
                    </el-submenu>
                    <!--        <el-submenu index="2">
                              <template slot="title" style="font-size: 0.875rem">OFADY</template>
                              <el-menu-item v-for="item in ofady" :index="item.mid.toString()" @click="goOfadyCarDetail(item.mid)">
                                {{ item.name }}
                              </el-menu-item>
                            </el-submenu>-->
                    <el-menu-item index="13" @click="showAllMenu=false;$router.push({ name: '/brandRelated' })">
                        {{ $t('header.about_ff') }}
                    </el-menu-item>
                    <!--进入售后服务中心，列表地图展示售后地点        -->
                    <el-menu-item index="14" @click="showAllMenu=false;$router.push({ name: '/service' })">
                        {{ $t('header.service_workshops') }}
                    </el-menu-item>
                    <el-submenu index="15">
                        <template slot="title" style="font-size: 0.875rem">{{
                                $t('header.find_distributor')
                            }}
                        </template>
                        <el-menu-item index="15_1" @click="showAllMenu=false;$router.push({ name: '/join' })">
                            {{ $t('header.join_us') }}
                        </el-menu-item>
                        <el-menu-item index="15_2" @click="showAllMenu=false;$router.push({ name: '/distributor' })">
                            {{ $t('header.distributor') }}
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="16" @click="showAllMenu=false;$router.push({ name: '/otherGoods' })">
                        {{ $t('header.other_goods') }}
                    </el-menu-item>
                    <el-divider content-position="left">{{ $t('header.area_lang') }}</el-divider>
                    <el-submenu index="17">
                        <template slot="title" style="font-size: 0.875rem">{{ nowCountry }}</template>
                        <el-menu-item v-for="(item,index) in countryList" :index="'17_'+index.toString()"
                                      @click="showAllMenu=false;changeCountry(item.value)">
                            {{ item.label }}
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="18">
                        <template slot="title" style="font-size: 0.875rem">{{ nowLanguage }}</template>
                        <el-menu-item index="18_1" @click="showAllMenu=false;changeLanguage('Español')">
                            Español
                        </el-menu-item>
                        <el-menu-item index="18_2" @click="showAllMenu=false;changeLanguage('中文')">
                            中文
                        </el-menu-item>
                        <el-menu-item index="18_3" @click="showAllMenu=false;changeLanguage('English')">
                            English
                        </el-menu-item>
                    </el-submenu>
                </el-menu>

            </el-drawer>
        </div>

    </div>
</template>

<script>
import {changeI18nLanguage} from "@/lang";
import httpApi from "@/http";
import cache from "@/utils/cache";

export default {
    name: "HeaderOfady",
    data() {
        return {
            lang: cache.getCache('LANGUAGE'),
            countryId: cache.getCache('COUNTRYID'),
            isHY: false,
            headerTop: true,
            left: 0,
            display: "none",
            brandList: [],
            nowLanguage: '',
            nowCountry: '',
            countryList: [],
            menuMode: 'horizontal',
            isPc: true,
            showAllMenu: false
        }
    },
    methods: {
        queryHeaderBrand() {
            httpApi.homeApi.queryHeaderBrand().then(res => {
                if (res.data.code === 200) {
                    let _ = this._;
                    let data = res.data.data;
                    if (data && this._.isArray(data) && data.length > 0) {
                        this.brandList = data;
                    }
                }
            });
        },
        // 点击车辆详情(车辆详情)
        goCarDetail(cid, index, bid) {
            this.showAllMenu = false;
            if (index === 0) {
                this.goFoalCarDetail(cid, bid);
            } else {
                this.goOfadyCarDetail(cid, bid);
            }
        },
        // 点击Foal车辆详情(车辆详情)
        goFoalCarDetail(cid, bid) {
            this.$router.push(
                {name: "/foalDetail", params: {model_id: bid, Cid: cid}},
                () => {
                }
            )
            if (this.$route.name == "/foalDetail") this.$router.go(0)
        },
        // 点击Ofady车辆详情(车辆详情)
        goOfadyCarDetail(cid, bid) {
            this.$router.push(
                {name: "/ofadyDetail", params: {model_id: bid, Cid: cid}},
                () => {
                }
            )
            if (this.$route.name == "/ofadyDetail") this.$router.go(0)
        },
        changeCountry(country) {
            // cache.setCache('COUNTRYID', country);
            let countryInfo = this._.find(this.countryList, function (c) {
                return c.value === country;
            })
            this.nowCountry = this._.get(countryInfo, 'label', '');
            if (this.nowCountry === '中国') {
                window.location.href = 'https://www.foalcar.com';
            } else {
                window.location.href = 'https://www.foalcar.mx';
            }
            // window.location.reload();
        },
        changeLanguage(language) {
            this.nowLanguage = language;
            let changeLang = language;
            if (language === 'English') {
                changeLang = 'EN';
            } else if (language === '中文') {
                changeLang = 'CN';
            } else {
                changeLang = 'SP';
            }
            console.log('changeLang', changeLang);
            changeI18nLanguage(changeLang);
            this.$i18n.locale = changeLang;
        },
        queryArea(id) {
            this.countryList = [];
            httpApi.commonApi.queryArea(id).then(res => {
                if (res.data.code === 200) {
                    let options = [];
                    let data = res.data.data;
                    let _ = this._;
                    _.each(data, function (d) {
                        options.push({
                            label: _.get(d, 'areaName', ''),
                            value: _.get(d, 'id')
                        })
                    });
                    this.countryList = options;
                    cache.setCache('COUNTRYLIST', options);
                    console.log('this.contryId：' + this.countryId);
                    if (this._.isUndefined(this.countryId) || this.countryId === '') {
                        const nowHref = window.location.href;
                        if (nowHref.indexOf('www.foalcar.com') > -1) {
                            /*中国网址*/
                            this.countryId = options[1].value;
                        } else {
                            /*墨西哥网址*/
                            this.countryId = options[0].value;
                        }
                        cache.setCache('COUNTRYID', this.countryId);
                    }
                    let countryId = this.countryId;
                    let countryInfo = _.find(options, function (o) {
                        return o.value === countryId
                    });
                    this.nowCountry = _.get(countryInfo, 'label', '');
                }
            })
        },
        showMenu: function () {
            this.showAllMenu = true;
        }
    },
    mounted() {
        if (window.innerWidth < 800) {
            this.isPc = false;
        }
        this.menuMode = this.isPc ? 'horizontal' : 'vertical';
        this.nowLanguage = this.lang === 'EN' ? 'English' : this.lang === 'CN' ? '中文' : 'Español'
        this.queryArea(0);
        this.queryHeaderBrand();


        if (this.$route.name == "PcHome") this.headerTop = false;
        if (this.$route.name == "indexHY") this.isHY = true;
    },
}
</script>

<style lang="scss" scoped>
.phone-header {
    display: flex;
    height: 4.5rem;
    background-color: #25ab9b;
    color: #ffffff;

    .phone-header-div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        > span {
            font-size: 60px;
            color: #fff;
        }

        img {
            width: 60px;
        }
    }
}

.H {
    transition: all 0.3s;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 111111;
}

.h_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    transform: scaleY(0);
    transform-origin: (50% 0%);
    height: 3rem;
    width: 100%;
    padding: 0 3% 0 5%;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.3s;
    z-index: 300;

    a {
        img {
            height: 4rem;
            margin-left: -2rem;
        }
    }

    .operation,
    .list_a {
        display: flex;

        .other,
        .item_a {
            position: relative;
            margin-right: 2vw;
            height: 3rem;
            line-height: 3rem;

            &:hover > ul {
                transform: scaleY(1);
            }

            i,
            a {
                text-decoration: none;
                color: #fff;
                font-size: 0.875rem;
            }

            > ul {
                position: absolute;
                top: 100%;
                left: -50%;
                width: 180%;
                transform: scaleY(0);
                transform-origin: (50% 0%);
                transition: all 0.3s;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 1000;

                li {
                    font-size: 0.875rem;
                    padding: 0 0.9375rem;
                    color: #fff;
                    text-align: center;
                    white-space: nowrap;
                    user-select: none;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
    }

    .operation {
        font-size: 0.875rem;
        color: #fff;

        div {
            margin: 0 1vw;
            height: 3rem;
            line-height: 3rem;

            &::before {
                margin-right: 0.3125rem;
            }

            &:nth-child(3) {
                ul {
                    width: 500%;
                    left: -200%;
                }
            }

            &.user_span {
                min-width: 3rem;

                span {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.el-menu--horizontal {
    .el-menu {
        .el-menu-item {
            &:hover {
                background-color: #1e897c !important;
                color: #fff !important;
            }
        }
    }
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 200;
    height: 4.5rem;
    width: 100%;
    padding: 0 2.5rem;
    transition: all 0.3s;
    background-color: #25ab9b;
    opacity: 0.9;

    > a {
        line-height: 1;

        img {
            height: 3.5rem;
            margin-left: -.5rem;
        }
    }

    .el-menu.el-menu--horizontal {
        border-bottom: none;

        .el-menu-item, .el-submenu__title {
            font-size: 0.875rem;
        }
    }

    .header_right,
    .type {
        position: relative;
        display: flex;
        height: 100%;

        div.el-dropdown {
            position: relative;
            line-height: 4.5rem;
            margin-right: 0.625rem;
            z-index: 1;

            &:hover > ul {
                transform: scaleY(1);
            }

            img {
                width: 4.5vw;
                max-width: 70px;
                cursor: pointer;
            }

            ul {
                position: absolute;
                top: 100%;
                left: -2rem;
                width: auto;
                transform: scaleY(0);
                transform-origin: (0% 0%);
                background-color: #25ab9b;
                transition: all 0.2s;
                transition-delay: 0.3s;

                li {
                    line-height: 1.5rem;
                    font-size: 14px;
                    padding: 0 0.625rem;
                    color: #fff;
                    white-space: nowrap;
                    user-select: none;
                    cursor: pointer;

                    &:hover {
                        background-color: #1e897c;
                    }
                }
            }

            &:nth-child(2) ul {
                left: -0.5rem;
            }

            &:nth-child(3) ul {
                left: -1rem;
            }

            &:nth-child(4) ul {
                left: -1rem;
            }

            &:nth-child(5) ul {
                left: 0;
            }
        }

        > .line {
            position: absolute;
            top: 70%;
            height: 0.125rem;
            width: 5vw;
            max-width: 70px;
            transition: all 0.3s;
            background-color: #fff;
        }
    }

    .hy {
        div {
            width: 4.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 1rem;
            }
        }
    }

    .header_right {
        color: #fff;

        .el-dropdown-link {
            cursor: pointer;
            color: #fff;
            height: 100%;
            display: inline-block;
        }

        .el-icon-arrow-down {
            font-size: 0.875rem;
        }

        div {
            font-size: 0.875rem;
            display: inline-block;
            //margin: 0 1.25rem;
            line-height: 4.5rem;
            cursor: pointer;

            &:before {
                font-size: 0.75rem;
                margin-right: 0.3125rem;
            }

            &:nth-child(3):before,
            &:nth-child(4):before {
                font-size: 1.25rem;
            }
        }
    }

    .car-name-white {
        display: inline-block;
        color: white;
        font-size: 0.875rem;
        width: 4.5vw;
        min-width: 70px;
        cursor: pointer;
    }
}

.el-dropdown-menu {
    background-color: #25ab9b;
    opacity: 0.9;

    .el-dropdown-menu__item {
        color: #fff;
    }
}

.el-dropdown-menu__item:focus, .el-dropdown-menu__item:hover {
    color: #fff !important;
    background-color: #1e897c !important;
}

</style>
<style lang="scss">
.ofady {
    font-size: 0.875rem !important;

    .el-submenu {
        i {
            font-size: 0.875rem !important;
            color: #ffffff !important;
        }
    }
}

.el-menu--popup-bottom-start {
    margin-top: 0.1rem !important;
    opacity: 0.9;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 4.5rem !important;
    line-height: 4.5rem !important;
}

.el-menu--horizontal > .el-menu-item {
    height: 4.5rem !important;
    line-height: 4.5rem !important;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: 4px solid;
}

.el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 4px solid;
}

.my-green-button {
    overflow: hidden;
    background-color: #25ab9b !important;

    &:hover {
        background-color: #1e897c !important;
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #dddddd;
}

</style>
