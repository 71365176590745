<template>
  <div id="app" style="height: 100%">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: "App",
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

button:active {
  opacity: 0.8;
}

html {
  scroll-behavior: smooth;
}

@media screen and (min-width: 1500px) {
  html {
    font-size: 20px;
  }
}

// @media screen and (max-width: 800px) {
//    html {
//       font-size: 12px;
//    }
// }
// body {
//    &::-webkit-scrollbar {
//       width: 0.3125rem;
//       background-color: #ddd;
//    }
//    &::-webkit-scrollbar-thumb {
//       border-radius: 0.625rem;
//       background: #bfbfbf;
//    }
// }
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #980000;
  border-color: #980000;
}

.el-input__inner:focus,
.el-checkbox__input.is-focus .el-checkbox__inner,
.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner,
.el-cascader .el-input .el-input__inner:focus,
.el-cascader .el-input.is-focus .el-input__inner,
.el-checkbox__inner:hover {
  border-color: #980000 !important;
}

.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-select-dropdown__item.selected {
  color: #980000 !important;
}
</style>
