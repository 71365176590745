export default {
    index: {
        drive_test: '预约试驾',
        know_him: '详细参数',
        modelos: '车型',
        view_more: '了解详情',
        why_ff: '选择FF的理由',
        other_goods: '周边商城',
        subscribe: '订阅我们',
    },
    header: {
        about_ff: '关于我们',
        service_workshops: '售后服务',
        find_distributor: '招商加盟',
        join_us: '加入我们',
        distributor: '经销商',
        other_goods: '周边商品',
        area_lang: '地区/语言'
    },
    footer: {
        quick_link: '快速链接',
        activity_list: '促销活动',
        other_goods: '周边商品',
        drive_test: '预约试驾',
        question_list: '常见问题',
        private_policy: '隐私政策',
        contact: '联系我们',
        terms_use: '使用条款',
        support: '技术支持',
        networks: '网络',
        copyright: '版权所有@foalcar',
        copyright_number: '粤ICP备10216027号',
        accept_cookies: '我们使用自己的和第三方cookies来确保网站及其插件的正确运行。如果您继续访问，请接受上述上下文。有关更多信息，请参阅我们的隐私政策。',
        accept: '接受',
        refuse: '拒绝'
    },
    activity: {
        activity_center: '促销活动',
    },
    car: {
        guiding_price: '指导价',
        view_360: '全景看车',
        view_drag_mouse: '鼠标拖拽查看',
        interior: '内饰',
        exterior: '外观',
        view_tip: '本版块为内容展示，实际车型配置以实车为准',
        order_now: '立即定制',
    },
    distributor: {
        distributor_list: '经销商列表',
    },
    join_us: {
        joining_conditions: '加盟条件',
        joining_process: '加盟流程',
        contact_us: '联系我们',
        contact_tel: '联系热线',
        contact_email: '联系邮箱',
        address_detail: '详细地址',
        tip_address: '请输入详细地址',
        company_name: '公司',
        tip_companyName: '请输入公司名称',
        company_industry: '行业',
        tip_companyIndustry: '请输入所属行业',
        join_money: '加盟金额',
        select_money: '请选择货币',
        tip_money: '请输入加盟金额',
        cancel: '取 消',
        confirm: '确定',
        loading: '提交中...',
        join_us: '申请加盟',
        submit_success: '提交成功，我们将尽快与您联系'
    },
    order: {
        confirm_information: '确认个人信息',
        name: '姓氏',
        phone: '手机号',
        email: '邮箱',
        selected_customization: '已选定制',
        tip: '以上车辆配置信息所用图片仅供参考，具体产品相关细节以最终交付实车为准。',
        selected_area: '省市/经销商',
        area: '地区',
        distributor: '经销商',
        please_select: '请选择',
        car_information: '商品信息',
        car_selected_price: '预选商品金额',
        agree: '我同意并勾选',
        agree_message: '订购须知',
        change_info: '修改配置',
        submit_info: '提交配置',
        tip_name: '请输入姓名',
        tip_phone: '请输入手机号',
        tip_number: '请输入数字',
        tip_email: '请输入邮箱',
    },
    other_goods: {
        country: '国家',
        select_country: '请选择国家',
        mx: '墨西哥',
        zh: '中国',
        am: '美国',
        name: '姓氏',
        phone: '手机号',
        email: '邮箱',
        click_now: '点击了解',
        detail: '详情',
        submit_success: '恭喜您！提交成功，稍后将有工作人员联系您！'
    },
    service_center: {
        list: '售后服务列表'
    },
    subscribe: {
        title: 'Hemos determinado tu ubicacion actual',
        remark: 'Haciendo clic en "Continuar hacia myAudi"aceptas expresamente el redireccionamiento automatico a la pagina web del pais seleccionado.Ademas,aceptas de ahora en adelante el redireccionamiento automatico a la pagina web del pais seleccionado.En esta pagina podras seleccionar otro pais otra region en cualquier momento.',
        confirm_subscribe: '提交订阅',
        submit_success: '订阅成功！'
    },
    drive_test: {
        drive_test: '预约试驾',
        tip: '欢迎您在线预约试驾，请准确填写以下信息，我们将为您提供更好的驾乘体验。',
        select_info: '请选择您想试驾的信息：',
        type: '车型：',
        select_date: '体验时间：',
        select_date_tip: '请选择日期：',
        buyer_info: '请填写联系人信息：',
        agree: '我已阅读并同意隐私条款',
        confirm_drive: '申请试驾',
        submit_success: '试驾信息添加成功,期待您的光临',
        is_required: '必填项',
        drive_number: '驾驶证号'
    },
    order_now: {
        customization: '车辆定制',
        next: '下一步',
        all_price: '预选合计',
        remark: '以上车辆配置信息所用图片仅供参考，具体产品相关细节以实车为准',
        submit_tip: "恭喜您！提交定制成功，稍后将有工作人员联系您！",
        submit_tip_fail: "提交失败，请完善相关信息后提交"
    }
}